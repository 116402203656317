
import {UniqueObject} from "./resource/UniqueObject";
import {NodeReference} from "./resource/NodeReference";

export class Connection implements UniqueObject {

  private _uuid: string = undefined;
  private _aNode: NodeReference = undefined;
  private _bNode: NodeReference = undefined;
  private _label: string = undefined;

  constructor (uuid: string, nodeA: NodeReference, nodeB: NodeReference, label: string) {
    this._uuid = uuid;
    this._aNode = nodeA;
    this._bNode = nodeB;
    this._label = label;
  }

  get uuid(): string {
   return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get aNode(): NodeReference {
    return this._aNode;
  }

  set aNode(value: NodeReference) {
    this._aNode = value;
  }

  get bNode(): NodeReference {
    return this._bNode;
  }

  set bNode(value: NodeReference) {
    this._bNode = value;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }
}
