

import {ContainerView} from "./containerview";
import {Drawable} from "./drawable";
import {NodeContentView} from "./nodeview";
import {CanvasItem} from "./canvasitem";
import {Vector4} from "./vector";

export class NodeContainerView extends ContainerView implements Drawable
{
  public xPos: number;
  public yPos: number;
  private _width: number;
  public height: number;

  public nodes: NodeContentView[] = [];
  public holes: boolean[] = [];
  public holesWithConnection: boolean[] = [];

  private holeOffset = 24;
  private cpOffset = 100;

  public style: number;

  /** Datatypes
   *
   * 1: text
   * 2: image
   * 3: website
   * 4: field
   *
   * */
  public dataType: number;


  constructor(public uuid: string, parent: CanvasItem, index: number, style: number, dataType: number) {
    super(uuid, parent, index);
    this.height = this.calcHeight(1);
    this.holes[0] = true;
    this.holes[1] = false;
    this.holes[2] = true;
    this.holes[3] = false;
    this.holesWithConnection[0] = false;
    this.holesWithConnection[1] = false;
    this.holesWithConnection[2] = false;
    this.holesWithConnection[3] = false;

    //console.log("new node container style: " + style);
    this.style = style;
    this.dataType = dataType;
  }


  calcHeight(zoom: number)
  {

    let res = 0;

    for (let node of this.nodes)
    {
      res += node.calcHeight(zoom);
    }

    if (zoom / this.getScale() <= .1)
    {
      //return 0;
    }

    return Math.max(res, 18);
  }


  public calcWidth(ctx, zoom: number): number {

    let max = this._width * zoom;

    for(let node of this.nodes)
    {
      max = Math.max(max, node.maxTextWidth(ctx, zoom));
    }

    return max;
  }

  draw(ctx, xOff: number, yOff: number, zoom: number)
  {

    let z = this.getChildZoomFor(zoom) / this.getScale();
    let x = this.xPos * 1 + xOff;
    let y = this.yPos * 1 + yOff;
    let r = 4;
    let rOff = 24;

    if(z <= .1)
    {
      return;
    }

    //console.log("cont h:" + this.height);
  ctx.save();
    ctx.beginPath();

    if (this.index == 0)
    {

      ctx.moveTo(x - this._width *.5 + r, y - this.height *.5);
      ctx.lineTo(x + this._width *.5 - r, y - this.height *.5);
      ctx.quadraticCurveTo(x + this._width * .5, y - this.height *.5, x + this._width *.5, y - this.height *.5 + r);
      ctx.lineTo(x + this._width *.5, y + this.height *.5 - r);
    }
    else
    {
      ctx.moveTo(x - this._width *.5, y - this.height *.5);
      ctx.lineTo(x + this._width *.5, y - this.height *.5);
      ctx.lineTo(x + this._width *.5, y + this.height *.5 - r);
    }

    if(this.index + 1 == this.parent.containers.length)
    {
      //ctx.lineTo(x + this.width *.5, y + this.height *.5 - r);
      ctx.quadraticCurveTo(x + this._width * .5, y + this.height *.5, x + this._width *.5 - r, y + this.height *.5 );
      ctx.lineTo(x - this._width *.5 + r, y + this.height *.5);
      ctx.quadraticCurveTo(x - this._width * .5, y + this.height *.5, x - this._width *.5, y + this.height *.5 - r);

    }
    else
    {
      ctx.lineTo(x + this._width *.5, y + this.height *.5);
      ctx.lineTo(x - this._width *.5, y + this.height *.5);
      ctx.lineTo(x - this._width *.5, y - this.height *.5 + r);
    }

    if (this.index == 0)
    {
      ctx.lineTo(x - this._width *.5, y - this.height *.5 + r);
      ctx.quadraticCurveTo(x - this._width * .5, y - this.height *.5, x - this._width *.5 + r, y - this.height *.5);
    }
    else
    {
      ctx.lineTo(x - this._width *.5, y - this.height *.5);
    }


    ctx.closePath();

    this.drawHoles(ctx, xOff, yOff, zoom);

    let node = this.nodes[0];

    //if(node && z <= .3333)
    if(node && z <= .5)
    {
      ctx.fillStyle = "#" + node.avgCol.r.toString(16) + node.avgCol.g.toString(16) + node.avgCol.b.toString(16);
      //console.log(ctx.fillStyle);
    }
    else
    {
      ctx.fillStyle = "white";
    }

    /*ctx.shadowColor = '#999';
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;*/
    ctx.shadowColor = 'rgba(0,0,0,0)';

    ctx.fill();
    ctx.closePath();
    ctx.clip();


    if (node)
    {
      //console.log(node.type);
      if(node.isImage())
      {
        //let pat = ctx.createPattern(node.img, "no-repeat");
        //ctx.fillStyle = pat;
        node.draw(ctx, xOff, yOff, zoom);
      }

    }


    ctx.restore();

    for (let node of this.nodes)
    {
      node.drawDots(ctx, xOff, yOff, zoom, this.holesWithConnection);

      if (node.isImage())
        continue;
      node.draw(ctx, xOff, yOff, zoom);
    }
  }

  public update(zoom: number)
  {
    for (let node of this.nodes)
    {
      node.setPos(this.xPos, this.yPos);
      node.width = this.width;
      node.height = this.height;
    }
  }

  public drawHoles(ctx: any, xOff: number, yOff: number, zoom: number) {

    let z = this.getChildZoomFor(zoom) / this.getScale();
    if (z <= .1)
    {
      return;
    }
    let x = this.xPos * 1 + xOff;
    let y = this.yPos * 1 + yOff;
    let r = 4;

    if(z >= 1)
    {
      r = 4
    }
    //else if (z >= .3333)
    else if (z >= .5)
    {
      r = r * z;
    }
    //else if (z <= .3333)
    else if (z <= .5)
    {
      r = 0;
    }

    let rOff = 24 * Math.min(z, 1);

    if (this.holes[0])
    {
      ctx.moveTo(x - this._width *.5, y - this.height *.5 + rOff - r);
      ctx.lineTo(x - this._width *.5, y - this.height *.5 + rOff + r);
      ctx.quadraticCurveTo(x - this._width *.5 + r, y - this.height *.5 + rOff + r, x - this._width *.5 + r, y - this.height *.5 + rOff);
      ctx.quadraticCurveTo(x - this._width *.5 + r, y - this.height *.5 + rOff - r, x - this._width *.5, y - this.height *.5 + rOff - r);
      //ctx.lineTo(x - t.width *.5 + r, y - t.height *.5 + rOff);
      ctx.closePath();
    }

    if (this.holes[1])
    {
      ctx.moveTo(x + r, y - this.height *.5);
      ctx.lineTo(x - r, y - this.height *.5);
      ctx.quadraticCurveTo(x - r, y - this.height *.5 + r, x, y - this.height *.5 + r);
      ctx.quadraticCurveTo(x + r, y - this.height *.5 + r, x + r, y - this.height *.5);
      ctx.closePath();
    }

    if (this.holes[2])
    {
      ctx.moveTo(x + this._width *.5, y - this.height *.5 + rOff - r);
      ctx.quadraticCurveTo(x + this._width *.5 - r, y - this.height *.5 + rOff - r, x + this._width *.5 - r, y - this.height *.5 + rOff);
      ctx.quadraticCurveTo(x + this._width *.5 - r, y - this.height *.5 + rOff + r, x + this._width *.5, y - this.height *.5 + rOff + r);
      ctx.lineTo(x + this._width *.5, y - this.height *.5 + rOff + r);
      ctx.closePath();
    }

    if (this.holes[3])
    {
      ctx.moveTo(x - r, y + this.height *.5);
      ctx.lineTo(x + r, y + this.height *.5);
      ctx.quadraticCurveTo(x + r, y + this.height *.5 - r, x, y + this.height *.5 - r);
      ctx.quadraticCurveTo(x - r, y + this.height *.5 - r, x - r, y + this.height *.5);
      ctx.closePath();
    }
  }

  public setHoles(r: boolean, t: boolean, l: boolean, b: boolean)
  {
    this.holes[0] = r;
    this.holes[1] = t;
    this.holes[2] = l;
    this.holes[3] = b;
  }

  public hasLeftHandle():boolean
  {
    return this.holes[0];
  }
  public hasTopHandle():boolean
  {
    return this.holes[1];
  }
  public hasRightHandle():boolean
  {
    return this.holes[2];
  }
  public hasBottomHandle():boolean
  {
    return this.holes[3];
  }

  public canHaveTopHandle():boolean
  {
    return this.parent.containers[0] == this;
  }

  public canHaveBottomHandle():boolean
  {
    return this.parent.containers[this.parent.containers.length - 1] == this;
  }

  public getHolePosition(index: number, set: boolean = false, zoom: number):Vector4
  {
    if (set && index >= 0 && index < this.holes.length)
    {
      this.holesWithConnection[index] = true;

      let hasOdd = false;
      let hasEven = false;

      for (let i=0; i<this.holesWithConnection.length; i++)
      {
        if (this.holesWithConnection[i])
        {
          if (i % 2 == 0)
            hasEven = true;
          else
            hasOdd = true;
        }
      }

      this.holes[0] = true;
      this.holes[1] = hasOdd && this.canHaveTopHandle();
      this.holes[2] = true;
      this.holes[3] = hasOdd && this.canHaveBottomHandle();
    }

    //console.log(this.yPos);
    //console.log(this.height);

    let res = new Vector4(0, 0, 0, 0);
    let z = Math.min(zoom / this.getScale(), 1);

    let h = Math.max(9, this.height * .5);

    switch (index)
    {
      case 0:
        res = new Vector4(this.xPos - this._width * .5, this.yPos - this.height * .5 + this.holeOffset * z,
          -this.cpOffset * z, 0);
        break;
      case 1:
        res = new Vector4(this.xPos, this.yPos - this.height * .5,
          0, -this.cpOffset * z);
        break;
      case 2:
        res = new Vector4(this.xPos + this._width * .5, this.yPos - this.height * .5 + this.holeOffset * z,
          this.cpOffset * z, 0);
        break;
      case 3:
        res = new Vector4(this.xPos, this.yPos + this.height * .5,
          0, this.cpOffset * z);
        break;
      default:
        res = new Vector4(this.xPos, this.yPos, this.xPos, this.yPos);
    }

    //console.log("conn zoo: " + zoom);
    if (zoom <= 0.1)
    {

      //res = new Vector4(this.xPos, this.yPos, this.xPos, this.yPos);
    }

    //res.mul(z);
    //console.log(res);
    return res;
  }

  public getChildZoomFor(zoom: number): number
  {

    let res = zoom;
    for(let node of this.nodes)
    {
      res = Math.max(node.getChildZoomFor(zoom), res);
    }

    return res;

  }


  public clearHoles()
  {
    this.holesWithConnection[0] = false;
    this.holesWithConnection[1] = false;
    this.holesWithConnection[2] = false;
    this.holesWithConnection[3] = false;
  }


  get width(): number {
    return this._width;
  }

  set width(value: number) {
    for (let node of this.nodes)
    {
      node.width = value;
    }
    this._width = value;
  }
}
