



import {NodeContentView} from "./nodeview";
import {Drawable} from "./drawable";
import {NodeContainerView} from "./nodeconatinerview";

export class WebsiteNodeContentView extends NodeContentView implements Drawable{

  protected icon = "";

  protected src: string;
  protected srcMedium: string;

  protected url: string;
  protected title: string;
  protected lastVisited: number;

  constructor(uuid: string, parent: NodeContainerView, content: any, stringValue: string) {
    super(uuid, parent, content, stringValue, 3, -1);


    this.srcMedium = "data:image/jpg;base64," + this.content.preview.thumbnailMedium;
    this.src = "data:image/jpg;base64," + this.content.preview.thumbnailSmall;
    this.ratio = this.content.preview.ratio;
    this.ratio = 1.3248731;

    let values = JSON.parse(this.stringValue);

    this.url = values.url;
    this.title = "🔗" + values.title;
    this.lastVisited = values.lastvisited;

    this.lines[0] = this.title;

    //console.log("vals");
    //console.log(values);
    //console.log("new image: " + this.src);


    this.img = new Image();
    //this.img.src = 'https://black-points.net/gallery/thumbnails/6.png';
    //this.img.src = 'https://black-points.net/gallery/full/12.jpeg';
    this.img.src = this.src;

    this.calcAvgColor();


  }


  draw(ctx: any, xOff: number, yOff: number, zoom: number)
  {




    switch (this.parent.style)
    {
      case 0:
        this.drawImage(ctx, xOff, yOff, zoom);
        if (zoom > 1)
        {
          this.img.src = this.srcMedium;
          this.drawImage(ctx, xOff, yOff, zoom);
        }
        //console.log("e; " + this.calcHeight(zoom));
        break;

      case 1:
        this.customHeight = 48;
        let z = zoom / this.parent.getScale();
        if (z < .5)
        {
          this.drawIcon(ctx, xOff, yOff, zoom);
          return;
        }
        this.stringValue = this.title;
        this.drawTitle(ctx, xOff, yOff, zoom);

    }

  }


  public isImage(): boolean {
    switch (this.parent.style)
    {
      case 0:
        return true;
      case 1:
      default:
        return false;
    }
  }


  public calcAvgColor(): any {

    if (this.parent.style == 1)
    {
      this.avgCol = {'r': 255, 'g': 255, 'b': 255};
      return;
    }
    let n = this;

    this.img.onload = function (e) {
      let res = n.getAverageRGB(n.img);

      n.avgCol.r = res.r;
      n.avgCol.g = res.g;
      n.avgCol.b = res.b;

      n.hasColor = true;
    };
  }
}
