
import {UniqueObject} from "./resource/UniqueObject";
import {Resource} from "./resource/Resource";

export class NodeModel implements UniqueObject {

  private _uuid: string = undefined;

  private _stringValue: string = undefined;

  private _type = 1;

  private _resource: Resource = undefined;


  private _height: number;

  private _style: number;

  private _xPos: number;
  private _yPos: number;


  constructor(uuid: string, stringValue: string, type: number, resource: Resource, height: number, style: number, xPos: number, yPos: number) {
    this._uuid = uuid;
    this._stringValue = stringValue;
    this._type = type;
    this._resource = resource;
    this._height = height;
    this._style = style;
    this._xPos = xPos;
    this._yPos = yPos;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get stringValue(): string {
    return this._stringValue;
  }

  set stringValue(value: string) {
    this._stringValue = value;
  }

  get type(): number {
    return this._type;
  }

  set type(value: number) {
    this._type = value;
  }

  get resource(): Resource {
    return this._resource;
  }

  set resource(value: Resource) {
    this._resource = value;
  }


  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = value;
  }

  get style(): number {
    return this._style;
  }

  set style(value: number) {
    this._style = value;
  }

  get xPos(): number {
    return this._xPos;
  }

  set xPos(value: number) {
    this._xPos = value;
  }

  get yPos(): number {
    return this._yPos;
  }

  set yPos(value: number) {
    this._yPos = value;
  }
}
