
import {UniqueObject} from "./resource/UniqueObject";
import {FieldItem} from "./FieldItem";
import {Connection} from "./Connection";

export class Field implements UniqueObject {

  private _uuid: string = undefined;

  titleString: string = undefined;

  private _items: Array<FieldItem> = undefined;

  private _connections: Array<Connection> = undefined;

  private _created: string = undefined;

  constructor() {
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get items(): Array<FieldItem> {
    return this._items;
  }

  set items(value: Array<FieldItem>) {
    this._items = value;
  }
  get connections(): Array<Connection> {
    return this._connections;
  }

  set connections(value: Array<Connection>) {
    this._connections = value;
  }

  get created(): string {
    return this._created;
  }

  set created(value: string) {
    this._created = value;
  }
}
