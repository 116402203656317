
export class UniqueViewObject {

  constructor(public _uuid: string) {
  }


  public get uuid(): string {
    return this._uuid;
  }


    public set uuid(value: string) {
        this._uuid = value;
    }
}
