
import {UniqueViewObject} from "./uniqueobject";
import {Drawable} from "./drawable";
import {NodeContainerView} from "./nodeconatinerview";
import {ConnectionView} from "./connection";

export class NodeContentView extends UniqueViewObject implements Drawable {

  xPos: number;
  yPos: number;
  private _width: number;
  height: number;
  public img;
  public avgCol = {'r': 255, 'g': 255, 'b': 255};
  public hasColor = false;
  public pat;
  public customHeight: number;
  public parent: NodeContainerView;
  public content: any;
  public stringValue: string;
  public type: number;
  public iconColor = "black";

  public ratio: number = 1;
  protected lines: Array<string>;

  protected icon = '';

  constructor(uuid: string, parent: NodeContainerView, content: any, stringValue: string, type: number, customHeight: number) {
    super(uuid);
    this.lines = [];

    this.xPos = 0;
    this.yPos = 0;
    this.parent = parent;
    this.customHeight = -1;
    if(customHeight)
      this.customHeight = customHeight;

    this.content = content;
    this.stringValue = stringValue;
    this.type = type;

    /*if (type == 2)
    {
     this.img = new Image();
     //this.img.src = 'https://black-points.net/gallery/thumbnails/6.png';
     //this.img.src = 'https://black-points.net/gallery/full/12.jpeg';
     this.img.src = content;
    }*/
  }

  setPos(x: number, y: number)
  {
    this.xPos = x;
    this.yPos = y;
  }

  set width(value: number) {
    this._width = value;
  }

  get width(): number {
    return this._width;
  }

  heightByType(zoom: number): number
  {
    let res = 48;
    //console.log(this);
    if (this.customHeight >= 0)
      return this.customHeight;
    switch (this.type)
    {
      case 1:
      case 4:
        res = 48 + this.getFontSize() * (this.lines.length - 1);
        break;
      case 2:
      case 3:
        //if(zoom >= .3333)
        if(zoom > 1)
        {
          //console.log("res fixed: "+ res);

          //console.log("width: " + this.width);
          //console.log("ratio: " + this.ratio);

          res = (this.width / this.ratio) / zoom;
        }
        else// if(zoom >= .5)
        {
          let scale = 1/this.parent.getScale();
          //console.log("!!!!!! z: " + zoom);
          //console.log("!!!!!! s: " + scale);
          res = (this.width / this.ratio) / Math.min(scale * zoom, 1);
          //res = 160 * zoom;
        }
        /*else
        {
          res = 48;

          //console.log("too small zoom: " + res + " zoom is: " + zoom + " calced: " + (zoom / this.parent.getScale()));
        }*/
        break;
    }
    //console.log("res hi: "+ res);
    return res;
  }

  calcHeight(zoom: number):number
  {
    let res = this.heightByType(zoom);

    let z = zoom / this.parent.getScale();
    let h = res * z;
    if(zoom <= 1)
      h = Math.min(h, res);
    else if (zoom > 1)
      h = Math.min(h, res * zoom);
    //else
    //  h = Math.min(h, res * zoom);

    //console.log("res2 hi: " + h);
    return h;
  }

  public getChildZoomFor(zoom: number): number
  {

    let res = zoom;

    return res;

  }


  draw(ctx: any, xOff: number, yOff: number, zoom: number)
  {



    //switch (this.type)
    //{
    //  case 1:
    //    this.drawTitle(ctx, xOff, yOff, zoom);
    //    break;
    //  case 2:
    //    this.drawImage(ctx, xOff, yOff, zoom);
    //    break;
    //}

  }


  public drawDots(ctx: any, xOff: number, yOff: number, zoom: number, holes: Array<boolean>)
  {

    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.strokeWidth = 0;
    ctx.fillStyle = ConnectionView.color;

    let z = Math.min(zoom / this.parent.getScale(), 1);
    let r = 3;

    if(z >= 1)
    {
      r = 3
    }
    else if (z >= .3333)
    {
      r = r * z;
    }
    else if (z <= .3333)
    {
      r = 0;
    }

    for (let i=0; i<holes.length; i++)
    {

      if(holes[i])
      {
        let v = this.parent.getHolePosition(i, false, zoom);
        ctx.beginPath();
        ctx.moveTo(v.x + xOff, v.y + yOff);
        ctx.arc(v.x + xOff, v.y + yOff, r, 0, 2*Math.PI);
        ctx.fill();
        ctx.closePath();
      }

    }
  }
/*
  drawTitle(ctx: any, xOff: number, yOff: number, zoom: number)
  {

    ctx.fillStyle = "black";
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.strokeWidth = 0;
    let z = zoom / this.parent.getScale();
    let fontSize = this.getFontSize();
    if(z > 1)
    {

      //z = zoom;
    }
    else if(z >= .6667)
    {
      fontSize = fontSize;
    }
    else if (z >= .3333)
    {
      fontSize = fontSize * (z + (1-.6667));
    }
    else
    {
      fontSize = 0;

      this.drawIcon(ctx, xOff, yOff, zoom);
      return;
    }

    if(zoom >= 1)
    {
      fontSize = fontSize * zoom;
      z = zoom;
    }
    else {
      z = Math.min(z, 1);
    }

    let xPad = 16 * (z);
    let yPad = 8 * (z);
    ctx.font = fontSize + "px essence";
    ctx.fillText(this.stringValue,this.xPos + xOff - this._width * .5 + xPad,this.yPos + yOff + yPad);

  }*/

  drawTitle(ctx: any, xOff: number, yOff: number, zoom: number)
  {

    //console.log("draw " + this.stringValue + " style " + this.parent.style);
    ctx.fillStyle = "black";
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.strokeWidth = 0;
    let z = zoom / this.parent.getScale();
    let fontSize = this.getFontSize();
    if(z > 1)
    {

      //z = zoom;
    }
    else if(z >= .6667)
    {
      fontSize = fontSize;
    }
    //else if (z >= .3333)
    else if (z >= .5)
    {
      //fontSize = fontSize * (z + (1-.6667));
    }
    else
    {
      fontSize = 0;

      this.drawIcon(ctx, xOff, yOff, zoom);
      return;
    }

    if(zoom >= 1)
    {
      fontSize = fontSize * zoom;
      z = zoom;
    }
    else {
      z = Math.min(z, 1);
      fontSize = fontSize * (z);
    }

    let xPad = 16 * (z);
    let yPad = 16 * z * (this.getFontSize() / 22);
    ctx.font = this.textDecoration() + " " + fontSize + "px " + this.getFont();
    let i=0;
    for (let line of this.lines)
    {
      let y = this.yPos - fontSize * this.lines.length * .5 + yOff + yPad;
      let text = line;
      let textWidth = ctx.measureText(text);
      //console.log(textWidth);
      if(textWidth.width > this.width)
        text = text.substring(0, text.length - 4) + "...";
      ctx.fillText(line,this.xPos + xOff - this.width * .5 + xPad,y + fontSize * i);
      i++;
      //console.log("line: " + line);
    }
    //ctx.fillText(this.content,this.xPos + xOff - this._width * .5 + xPad,(this.yPos + yOff) * 1 + yPad);

  }

  drawImage(ctx: any, xOff: number, yOff: number, zoom: number)
  {

    let z = zoom / this.parent.getScale();
    if(z > 1)
    {
    }
    else if(z >= .6667)
    {
    }
    //else if (z >= .3333)
    else if (z >= .5)
    {
    }
    else
    {
      this.drawIcon(ctx, xOff, yOff, zoom);
      return;
    }

    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.strokeWidth = 0;
    ctx.imageSmoothingEnabled = true;

      //let pattern = ctx.createPattern(img, 'no-repeat');
      //ctx.fillStyle = pattern;
      //ctx.fill();

    ctx.drawImage(this.img, this.xPos + xOff - this._width * .5, this.yPos + yOff - this.height * .5, this._width, this.height);

    ctx.imageSmoothingEnabled = false;
  }


  public maxTextWidth(ctx: any, zoom: number): number
  {
    //console.log("draw " + this.stringValue + " style " + this.parent.style);
    ctx.fillStyle = "black";
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.strokeWidth = 0;
    //let z = zoom / this.parent.getScale();
    let z = zoom;// * this.parent.getScale();
    let fontSize = this.getFontSize();

    if(zoom >= 1)
    {
      fontSize = fontSize * zoom;
      z = zoom;
    }
    else {
      z = Math.min(z, 1);
      fontSize = fontSize * (z);
    }
    ctx.font = this.textDecoration() + " " + fontSize + "px " + this.getFont();
    let i=0;
    let max = 0;
    let xPad = 16 * (z);
    for (let line of this.lines) {
      let textWidth = ctx.measureText(line).width + xPad * 2;

      i++;
      //console.log("sc: " + z);
      //console.log("wi: " + this.width);
      //console.log("tw: " + ctx.measureText(line).width);
      //console.log("pd: " + xPad * 2);
      if (textWidth > max)
      {
        //console.log("new textwidth: " + textWidth);
        max = textWidth
      }

    }


    return max;
  }

  public drawIcon(ctx: any, xOff: number, yOff: number, zoom: number)
  {
    ctx.fillStyle = this.iconColor;
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.strokeWidth = 0;
    let z = zoom / this.parent.getScale();
    let fontSize = 14;

    ctx.font = fontSize + "px essence";
    let txt = "";
    if(this.type == 1)
      txt = "";
    else if(this.type == 2)
      txt = "";
    else if(this.type == 3)
      txt = "🔗";
    else if(this.type == 4)
      txt = "";

    let txtWidth = ctx.measureText(txt);
    let xPad = (this._width - txtWidth.width) * .5;
    let yPad = 3;
    ctx.fillText(txt,this.xPos + xOff - this._width * .5 + xPad,this.yPos + yOff + yPad);
  }


  public calcAvgColor()
  {
    if (this.hasColor)
      return;
    //if(this.isImage())
    //{
    //  this.avgCol.r = 255; //Math.floor(Math.random() * 256);
    //  this.avgCol.g = 255; //Math.floor(Math.random() * 256);
    //  this.avgCol.b = 255; //Math.floor(Math.random() * 256);
    //  //console.log(this.avgCol);
    //}
    this.hasColor = true;
  }

  public getFontSize(): number
  {
    return 18;
  }


  public textDecoration() {
    if (this.type != 1)
      return "";
    switch (this.parent.style) {
      case 0:
        return "";
      default:
        return "";
    }
  }

  public getFont(): string
  {
    if (this.type != 1)
      return "essence-regular";
    switch (this.parent.style)
    {
      case 0:
        return "essence-bold";
      case 2:
        return "essence-regular";
      case 1:
        return "essence-regular";
      default:
        return "essence-regular";
    }
  }

  public isImage(): boolean
  {
    return false;
  }

  protected getAverageRGB(imgEl) {

    var blockSize = 5, // only visit every 5 pixels
      defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
      canvas = document.createElement('canvas'),
      context = canvas.getContext && canvas.getContext('2d'),
      data, width, height,
      i = -4,
      length,
      rgb = {r:0,g:0,b:0},
      count = 0;

    if (!context) {
      return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
      data = context.getImageData(0, 0, width, height);
    } catch(e) {
      /* security error, img on diff domain */
      return defaultRGB;
    }

    length = data.data.length;

    while ( (i += blockSize * 4) < length ) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i+1];
      rgb.b += data.data[i+2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);

    let sum = rgb.r + rgb.g + rgb.b;

    //console.log("calced avg col: ");
    //console.log(rgb);
    if (sum / 3 <= 127)
    {
      //this.iconColor = "rgb("+rgb.r+","+rgb.g+","+rgb.b+")";
      this.iconColor = "rgb(255,255,255)";

    }

    return rgb;

  }




}
