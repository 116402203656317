
import {UniqueViewObject} from "./uniqueobject";
import {NodeContainerView} from "./nodeconatinerview";
import {Vector4} from "./vector";

export class ConnectionView extends UniqueViewObject
{

  public xStart: number;
  public yStart: number;
  public xC1: number;
  public yC1: number;
  public xC2: number;
  public yC2: number;
  public xEnd: number;
  public yEnd: number;

  public static color = "rgb(111, 111, 111)";

  private cpOffset: number;

  /*constructor(
    public xStart: number,
    public yStart: number,
    public xC1: number,
    public yC1: number,
    public xC2: number,
    public yC2: number,
    public xEnd: number,
    public yEnd: number) {
  }*/


  constructor(
    public uuid:string,
    public aNode:NodeContainerView,
    public bNode:NodeContainerView) {
    super(uuid);
    this.cpOffset = 100;
  }


  draw(ctx, xOff: number, yOff: number, zoom: number)
  {
    //console.log(this);
    let xs = this.xStart + xOff;
    let ys = this.yStart + yOff;
    let xe = this.xEnd + xOff;
    let ye = this.yEnd + yOff;
    //console.log(xs + " " + ys + " " + xe + " " + ye + " ")

    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    if (zoom >= .50)
    {
      ctx.strokeWidth = 4;
    }
    else if (zoom < .5)
    {
      ctx.strokeWidth = 4 * (zoom / .50);
    }
    else if (zoom <= .1)
    {
      ctx.strokeWidth = 1;
    }
    ctx.fillStyle = "transparent";
    ctx.strokeStyle = ConnectionView.color;
    ctx.beginPath();
    ctx.moveTo(xs, ys);
    if(zoom <= .1)
    {
      ctx.lineTo(xe, ye);
    }
    else
      ctx.bezierCurveTo(xs + this.xC1 * 1, ys + this.yC1 * 1, xe + this.xC2 * 1, ye + this.yC2 * 1, xe, ye);
  ctx.stroke();
    ctx.closePath();

  }

  public calc(zoom: number)
  {
    /*if(!(this.aNode || this.bNode))
    {
      console.log("node missing");
      return;
    }*/
    let boundsOfAContainer = this.aNode.getBounds();
    let boundsOfBContainer = this.bNode.getBounds();

    let BLeftFromA = boundsOfBContainer.topRight.x <
      boundsOfAContainer.topLeft.x;
    let BOverlapXWithA = boundsOfBContainer.topRight.x >=
      boundsOfAContainer.topLeft.x && boundsOfBContainer.topLeft.x <=
      boundsOfAContainer.topRight.x;
    let BAboveA = boundsOfBContainer.bottomLeft.y <
      boundsOfAContainer.topLeft.y;
    let BOverlapYWithA = boundsOfBContainer.bottomLeft.y >=
      boundsOfAContainer.topLeft.y && boundsOfBContainer.topLeft.y <=
      boundsOfAContainer.bottomLeft.y;

    let LessThanHalfOnLeft = boundsOfBContainer.topRight.x >=
      boundsOfAContainer.topLeft.x && boundsOfBContainer.topRight.x <
      boundsOfAContainer.center.x;
    let LessThanHalfOnRight = boundsOfBContainer.topLeft.x <=
      boundsOfAContainer.topRight.x && boundsOfBContainer.topLeft.x >=
      boundsOfAContainer.center.x;

    //layout david: connection logic
    if (BLeftFromA) {
      this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(2, true, zoom));
    }
    else if (BOverlapXWithA) {
      if (BAboveA) {
        if (this.aNode.canHaveTopHandle() && this.bNode.canHaveBottomHandle()) {
          this.setPositions(this.aNode.getHolePosition(1, true, zoom), this.bNode.getHolePosition(3, true, zoom));
        }
        else if (this.aNode.canHaveTopHandle()) {
          if (LessThanHalfOnLeft) {
            this.setPositions(this.aNode.getHolePosition(1, true, zoom), this.bNode.getHolePosition(2, true, zoom));
          }
          else if (LessThanHalfOnRight) {
            this.setPositions(this.aNode.getHolePosition(1, true, zoom), this.bNode.getHolePosition(0, true, zoom));
          }
          //MoreThanHalfway
          else {

            this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
          }
        }
        else if (this.bNode.canHaveBottomHandle()) {
          if (LessThanHalfOnLeft) {
            this.setPositions(this.aNode.getHolePosition(2, true, zoom), this.bNode.getHolePosition(3, true, zoom));
          }
          else if (LessThanHalfOnRight) {
            this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(3, true, zoom));
          }
          //MoreThanHalfway
          else {
            this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
          }
        }
        //no direct docks
        else {

          this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
        }
      }
      else if (BOverlapYWithA) {
        //should error

        this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
      }
      //BBelowA
      else {
        if (this.aNode.canHaveBottomHandle() && this.bNode.canHaveTopHandle()) {
          this.setPositions(this.aNode.getHolePosition(3, true, zoom), this.bNode.getHolePosition(1, true, zoom));
        }
        else if (this.aNode.canHaveBottomHandle()) {
          if (LessThanHalfOnLeft) {

            this.setPositions(this.aNode.getHolePosition(3, true, zoom), this.bNode.getHolePosition(2, true, zoom));
          }
          else if (LessThanHalfOnRight) {
            this.setPositions(this.aNode.getHolePosition(3, true, zoom), this.bNode.getHolePosition(0, true, zoom));
          }
          //MoreThanHalfway
          else {
            this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
          }
        }
        else if (this.bNode.canHaveTopHandle()) {
          if (LessThanHalfOnLeft) {
            this.setPositions(this.aNode.getHolePosition(2, true, zoom), this.bNode.getHolePosition(1, true, zoom));
          }
          else if (LessThanHalfOnRight) {
            this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(1, true, zoom));
          }
          //MoreThanHalfway
          else {
            this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
          }
        }
        //no direct docks
        else {
          this.setPositions(this.aNode.getHolePosition(0, true, zoom), this.bNode.getHolePosition(0, true, zoom));
        }
      }
    }
    //BRightFromA
    else {
      this.setPositions(this.aNode.getHolePosition(2, true, zoom), this.bNode.getHolePosition(0, true, zoom));
    }

  }

  setPositions(a: Vector4, b: Vector4)
  {
    this.xStart = a.x;
    this.yStart = a.y;
    this.xC1 = a.z;
    this.yC1 = a.w;

    this.xC2 = b.z;
    this.yC2 = b.w;
    this.xEnd = b.x;
    this.yEnd = b.y;

  }



}
