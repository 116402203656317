
import {UniqueViewObject} from "./uniqueobject";
import {Drawable} from "./drawable";
import {CanvasItem} from "./canvasitem";
import {Bounds} from "./bounds";

export abstract class ContainerView extends UniqueViewObject implements Drawable
{


  xPos: number;
  yPos: number;
  width: number;
  height: number;


  constructor(public uuid: string, public parent: CanvasItem, public index: number) {
    super(uuid);
    this.index = index;
    this.parent = parent;
  }


  calcHeight(zoom: number)
  {

    return 0;
  }

  public calcWidth(ctx, zoom: number): number
  {
    return 0;
  }


  abstract draw(ctx, xOff: number, yOff: number, zoom: number);
  abstract drawHoles(ctx, xOff: number, yOff: number, zoom: number);
  public clearHoles()
  {
  }
  abstract update(zoom: number);

  public getScale(): number
  {
    return this.parent.getScale();
  }

  public getBounds(): Bounds
  {
    return new Bounds(this.xPos, this.yPos, this.width, this.height);
  }

  public getChildZoomFor(zoom: number): number
  {

    return zoom;

  }
}
