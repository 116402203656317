
import {UniqueViewObject} from "./uniqueobject";
import {Drawable} from "./drawable";
import {ContainerView} from "./containerview";
import {ConnectionView} from "./connection";

export class CanvasItem extends UniqueViewObject implements Drawable{


  public containers: ContainerView[] = [];


  height: number;
  public scale: number;
  public diveScore: number = 1;
  public prevZoom: number = 1;

  private drawHelper: any = {};


  constructor(public uuid: string, public xPos: number, public yPos: number, public width: number, scale: number = 1, diveScore: number) {
    super(uuid);
    this.scale = scale;
    this.diveScore = diveScore;
    //console.log("x: " + xPos + " y: " + yPos);
  }



  public calcDive(zoom: number, avg: number, min: number)
  {
    let topX = 0.1;
    let topY = 0.3;
    let minDeviationFromDiveScoreAverage = (min - avg) / avg;
    let deviationFromDiveScoreAverage = (this.diveScore - avg) / avg - minDeviationFromDiveScoreAverage;
    let convertedScale = 1;
    /*if (zoom >= topX)
    {
      convertedScale = topY - Math.pow(Math.sqrt((topY) * (1 / (1 - topX)) * (zoom - topX)), 2);
    }
    else {
      convertedScale = topY - Math.pow((Math.sqrt(topY * (1 / (topX)) * (zoom - topX))), 2);
    }*/
    let deviationFromScale = deviationFromDiveScoreAverage * convertedScale;
    let scaleForItem = zoom + deviationFromScale;

    this.scale = scaleForItem;

    //console.log("scale: " + this.scale);
  }

  public zoomByScale(zoom: number): number
  {
    return zoom * (1 / this.getScale());
  }

  public getScale(): number
  {
    return 1 / this.scale;
  }

  calcHeight(zoom: number)
  {

    let res = 0;
    let z = zoom;

    for (let container of this.containers)
    {
      res += container.calcHeight(z);
      //console.log("calc height1: " + res);
      //console.log("el hi: " + res);
    }
    res -= (this.containers.length - 1) * this.getChildZoomFor(z);
    //console.log("calc height2: " + res);

    //console.log("res hi: " + res);
    if (this.getChildZoomFor(this.zoomByScale(z)) <= .1)
    {
      //console.log("zoo: " + this.getChildZoomFor(this.zoomByScale(z)) + " break it");
      return 0;
    }


    //console.log("calc height3: " + res);
    return Math.max(res, 0);
  }

  public widthFor(ctx, zoom: number): number
  {
    let z = this.getChildZoomFor(zoom);
    //if(z >= .3333)
    if (z > 1)
      return this.width * z;
    if(z >= .5)
      return this.calcWidth(ctx, z)/* * Math.sqrt(Math.min(z, 1))*/;
    else if(z > .1)
      return 28 + 28 * (z * 2);
    else
      return 0;
  }

  public calcWidth(ctx, zoom: number): number
  {
    let w = this.width * zoom * zoom;
    let max = w;

    //console.log("--------");

    for(let container of this.containers)
    {
      max = Math.max(max, container.calcWidth(ctx, zoom));
    }

    //console.log("max width: " + max + " zoom: " + zoom);
    //console.log("width: " + w);

    //if (w < max)
    //{
      //console.log("new width: " + max + " instead of " + w);
    //}

    //console.log("m: " + max);
    return max;
  }

  public getChildZoomFor( zoom: number): number
  {

    let z = zoom;
    let res = z;
    for(let container of this.containers)
    {
      res = Math.max(container.getChildZoomFor(z), res);
    }

    return res;

  }

  draw(ctx, xOff: number, yOff: number, zoom: number, avgDive: number, minDive: number)
  {

    //this.update(ctx, zoom, avgDive, minDive);
    //let z = this.getChildZoomFor(this.zoomByScale(zoom));
    //console.log("ys: " + ys);
    /*let ys = (this.yPos * this.getChildZoomFor(zoom) - this.height * .5);
    for(let container of this.containers)
    {
      ys += container.height * .5;
      container.height = container.calcHeight(zoom);
      container.width = this.widthFor(zoom);
      container.xPos = this.xPos * this.getChildZoomFor(zoom);
      container.yPos = ys;
      ys += container.height * .5 - 1;
    }*/

    let x = this.xPos * zoom + xOff;
    let y = this.yPos * zoom + yOff;
    let r = 4;
    let z = this.drawHelper.z;
    let w = this.drawHelper.w;
    let h = this.drawHelper.h;
    /*let w = this.widthFor(ctx, z);
    if(zoom <= 1)
      w = Math.min(w, this.width);
    else
      w = Math.min(w, this.widthFor(ctx, zoom));
    //w = this.widthFor(ctx, z);
    let h = this.height;
    if(zoom <= 1)
      h = Math.min(h, this.calcHeight(zoom)) * .5;
    else
      h = Math.min(h, this.calcHeight(zoom)) * .5;*/


    h = h - this.containers.length + 1;

    //console.log("shad h:" + (2*h));

    if (z > .1)
    {
      ctx.beginPath();
      ctx.moveTo(x - w + r, y - h);
      ctx.lineTo(x + w - r, y - h);
      ctx.quadraticCurveTo(x + w, y - h, x + w, y - h + r);
      ctx.lineTo(x + w, y + h - r);
      ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h );
      ctx.lineTo(x - w + r, y + h);
      ctx.quadraticCurveTo(x - w, y + h, x - w, y + h - r);
      ctx.lineTo(x - w, y - h + r);
      ctx.quadraticCurveTo(x - w, y - h, x - w + r, y - h);
      ctx.closePath();


      for(let container of this.containers)
      {
        container.drawHoles(ctx, xOff, yOff, zoom);
      }

      ctx.fillStyle = "#999";
      ctx.shadowColor = "#999";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;

      //ctx.filter = "drop-shadow(2px, 2px, 10px, #999)";
      ctx.fill();
      ctx.closePath();
    }
    ctx.filter = "none";

    for(let container of this.containers)
    {

      container.draw(ctx, xOff, yOff, zoom);
    }

    if (z <= .1)
    {
      ctx.shadowColor = "rgba(0, 0, 0, 0)";
      ctx.strokeWidth = 0;
      ctx.fillStyle = ConnectionView.color;

      let r = Math.max(8 * (z / .1), 3);

      ctx.beginPath();
      ctx.moveTo(this.xPos * zoom + xOff, this.yPos * zoom + yOff);
      ctx.arc(this.xPos * zoom + xOff, this.yPos * zoom + yOff, r, 0, 2*Math.PI);
      ctx.fill();
      ctx.closePath();


    }
  }

  public update(ctx, zoom: number, avgDive: number, minDive: number)
  {
    this.calcDive(zoom, avgDive, minDive);
    let z = this.getChildZoomFor(this.zoomByScale(zoom));
    //this.height = this.calcHeight(zoom);
    let w = this.widthFor(ctx, z);
    if(zoom <= 1)
      w = Math.min(w, this.width);
    else
      w = Math.min(w, this.widthFor(ctx, zoom));

    for(let container of this.containers) {

      container.width = w;
    }
    //w = this.widthFor(ctx, z);
    let h;// = this.height;
    //if(zoom <= 1)
    //  h = Math.min(h, this.calcHeight(zoom)) * .5;
    //else
      h = Math.min(h / z, this.calcHeight(zoom)) ;
      h = this.calcHeight(zoom) ;


    if (zoom > 1)
    {
      h = this.calcHeight(zoom);
    }

    h = Math.max(h, 18 * this.containers.length);
    if (z <= 0.1)
    {
      h = 0;
    }
    this.height = h;
    //console.log("ys: " + ys);

    let ys = (this.yPos * zoom - h * .5);
    //console.log("ys1: " + ys);
    for(let container of this.containers)
    {
      if (z < 0.1)
      {
        container.height = 0;
        container.width = 0;
        container.xPos = this.xPos * zoom;
        container.yPos = this.yPos * zoom;
        //w = 0;
      }
      else
      {
        container.height = container.calcHeight(zoom);
        ys += container.height * .5;
        container.width = w;
        container.xPos = this.xPos * zoom;
        container.yPos = ys;
        ys += container.height * .5 - 1;
      }

      h = Math.max(h, container.height);

      container.update(zoom);
    }

    this.drawHelper = {
      "z": z,
      "w": w * .5,
      "h": h * .5
    };
  }

  public clearHoles()
  {
    for (let c of this.containers)
    {
      c.clearHoles();
    }
  }
}
