

import {Vector2} from "./vector";

export class Bounds
{

  public topLeft: Vector2;
  public topRight: Vector2;
  public bottomRight: Vector2;
  public bottomLeft: Vector2;
  public centerLeft: Vector2;
  public centerTop: Vector2;
  public centerRight: Vector2;
  public centerBottom: Vector2;
  public center: Vector2;

  constructor(xPos: number, yPos: number, width: number, height: number) {
    this.topLeft = new Vector2(xPos - width * .5, yPos - height * .5);
    this.topRight = new Vector2(xPos + width * .5, yPos - height * .5);
    this.bottomRight = new Vector2(xPos + width * .5, yPos + height * .5);
    this.bottomLeft = new Vector2(xPos - width * .5, yPos + height * .5);
    this.centerLeft = new Vector2(xPos - width * .5, yPos);
    this.centerTop = new Vector2(xPos, yPos - height * .5);
    this.centerRight = new Vector2(xPos + width * .5, yPos);
    this.centerBottom = new Vector2(xPos, yPos + height * .5);
    this.center = new Vector2(xPos, yPos);
  }
}
