export class Vector2
{

  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export class Vector4
{

  public x: number;
  public y: number;
  public z: number;
  public w: number;


  constructor(x: number, y: number, z: number, w: number) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.w = w;
  }

  public mul(m: number)
  {
    this.x = this.x * m;
    this.y = this.y * m;
    this.z = this.z * m;
    this.w = this.w * m;

  }
}
