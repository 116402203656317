
import {NodeContentView} from "./nodeview";
import {Drawable} from "./drawable";
import {NodeContainerView} from "./nodeconatinerview";

export class TextNodeContentView extends NodeContentView implements Drawable{

  protected icon = "";


  /** Styles:
   *
   * 0: Title
   * 2: Subtitle
   *
   * */

  constructor(uuid: string, parent: NodeContainerView, content: any, stringValue: string) {
    super(uuid, parent, content, stringValue, 1, -1);


    let i = 0;
    let subi = -1;
    let psubi = 0;

    while(true)
    {
      subi = content.indexOf("\n", subi) + 1;

      if (subi == 0)
      {

        this.lines[i] = content.substring(psubi);

        break;
      }
      this.lines[i] = content.substring(psubi, subi);

      psubi = subi;
      i++;



    }


  }

  heightByType(zoom: number): number
  {
    let res = 48;
    switch (this.type)
    {
      case 1:
        res = 48 + 22 * (this.lines.length - 1);
        break;
      case 2:
        if(zoom >= .3333)
          res = 160;
        else
          res = 48;
        break;
    }
    return res;
  }

  calcHeight(zoom: number):number
  {
    let res = this.heightByType(zoom);

    let z = zoom / this.parent.getScale();
    let h = res * z;
    if(zoom <= 1)
      h = Math.min(h, res);
    else
      h = Math.min(h, res * zoom);

    return h;
  }

  public getChildZoomFor(zoom: number): number
  {

    let res = zoom;

    return res;

  }


  draw(ctx: any, xOff: number, yOff: number, zoom: number)
  {


    switch (this.type)
    {
      case 1:
        this.drawTitle(ctx, xOff, yOff, zoom);
        break;
      case 2:
        this.drawImage(ctx, xOff, yOff, zoom);
        break;
    }

  }

  drawTitle(ctx: any, xOff: number, yOff: number, zoom: number)
  {

    //console.log("draw " + this.stringValue + " style " + this.parent.style);
    ctx.fillStyle = "black";
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.strokeWidth = 0;
    let z = zoom / this.parent.getScale();
    let fontSize = this.getFontSize();
    if(z > 1)
    {

      //z = zoom;
    }
    else if(z >= .6667)
    {
      fontSize = fontSize;
    }
    //else if (z >= .3333)
    else if (z >= .5)
    {
      //fontSize = fontSize * (z + (1-.6667));
    }
    else
    {
      fontSize = 0;

      this.drawIcon(ctx, xOff, yOff, zoom);
      return;
    }

    if(zoom >= 1)
    {
      fontSize = fontSize * zoom;
      z = zoom;
    }
    else {
      z = Math.min(z, 1);
      fontSize = fontSize * (z);
    }

    let xPad = 16 * (z);
    let yPad = 16 * z * (this.getFontSize() / 22);
    ctx.font = this.textDecoration() + " " + fontSize + "px " + this.getFont();
    let i=0;
    for (let line of this.lines)
    {
      let y = this.yPos - fontSize * this.lines.length * .5 + yOff + yPad;
      let text = line;
      let textWidth = ctx.measureText(text);
      //console.log(textWidth);
      //if(textWidth > this.width)
      //  text = text.substring(0, text.length - 4) + "...";
      ctx.fillText(line,this.xPos + xOff - this.width * .5 + xPad,y + fontSize * i);
      i++;
      //console.log("line: " + line);
    }
    //ctx.fillText(this.content,this.xPos + xOff - this._width * .5 + xPad,(this.yPos + yOff) * 1 + yPad);

  }


  public getFontSize(): number
  {
    switch (this.parent.style)
    {
      case 0:
        return 26;
      case 2:
        return 22;
      case 1:
        return 18;
      default:
        return super.getFontSize();
    }

  }


}
