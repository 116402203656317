
import {UniqueObject} from "./resource/UniqueObject";
import {Container} from "./Container";

export class FieldItem implements UniqueObject {

  private _uuid: string = undefined;

  private _xPos: number = undefined;

  private _yPos: number = undefined;

  private _width: number = undefined;

  private _scale = 1;

  private _diveScore = 1;

  private _containers: Array<Container> = undefined;

  constructor(uuid: string, xPos: number, yPos: number, width: number, scale: number, diveScore: number, containers: Array<Container>) {
    this._uuid = uuid;
    this._xPos = xPos;
    this._yPos = yPos;
    this._width = width;
    this._scale = scale;
    this._diveScore = diveScore;
    this._containers = containers;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get xPos(): number {
    return this._xPos;
  }

  set xPos(value: number) {
    this._xPos = value;
  }

  get yPos(): number {
    return this._yPos;
  }

  set yPos(value: number) {
    this._yPos = value;
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  get scale(): number {
    return this._scale;
  }

  set scale(value: number) {
    this._scale = value;
  }

  get containers(): Array<Container> {
    return this._containers;
  }

  set containers(value: Array<Container>) {
    this._containers = value;
  }


  get diveScore(): number {
    return this._diveScore;
  }

  set diveScore(value: number) {
    this._diveScore = value;
  }
}
