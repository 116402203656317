
import {UniqueObject} from "./resource/UniqueObject";
import {Connection} from "./Connection";
import {NodeModel} from "./Node";

export class Container implements UniqueObject {
  private _uuid: string = undefined;

  private _nodes: Array<NodeModel> = undefined;

  private _connections: Array<Connection> = undefined;

  private _dataType: number;
  private _order: number;
  private _style: number;



  constructor(uuid: string, nodes: Array<NodeModel>, connections: Array<Connection>, dataType: number, order: number, style: number) {
    this._uuid = uuid;
    this._nodes = nodes;
    this._connections = connections;
    this._dataType = dataType;
    this._order = order;
    this._style = style;
  }


  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get nodes(): Array<NodeModel> {
    return this._nodes;
  }

  set nodes(value: Array<NodeModel>) {
    this._nodes = value;
  }

  get connections(): Array<Connection> {
    return this._connections;
  }

  set connections(value: Array<Connection>) {
    this._connections = value;
  }


  get dataType(): number {
    return this._dataType;
  }

  set dataType(value: number) {
    this._dataType = value;
  }

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  get style(): number {
    return this._style;
  }

  set style(value: number) {
    this._style = value;
  }
}
