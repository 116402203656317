import {AfterViewChecked, AfterViewInit, Component} from '@angular/core';
import {FieldImporter} from "../canvas/FieldImporter";
import {Canvas} from "../canvas/canvas";
import * as $ from "jquery";
import {
    ActivatedRoute, PRIMARY_OUTLET, Router, RoutesRecognized, UrlSegment, UrlSegmentGroup,
    UrlTree
} from "@angular/router";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
    get sub(): any {
        return this._sub;
    }

    set sub(value: any) {
        this._sub = value;
    }

    title = 'app';

    private _token: string;


    constructor(private route: ActivatedRoute, private router: Router) {
        this._token = "initial"
    }

    private _sub: any;

    public getToken = (): string => {
        return this._token
    };

    set token(value: string) {
        this._token = value;
    }

    public ngAfterViewInit() {
        this.initializePage()
    }


    private initializePage = () => {
        this.token = "avi";
        console.info("Hello Viewinit " + this.getToken());
        this._sub = this.router.events.subscribe((val) => {
                if (!(val instanceof RoutesRecognized)) {
                    return;
                }
                else {
                    console.log(val.state.root.firstChild.params);
                    let params = val.state.root.firstChild.params;
                    console.log("subbed: " + this._token);
                    this.token = params['token'];
                    console.log("new token is " + JSON.stringify(params));
                    let loadingTexts = [
                        "hello there :)",
                        "hang in there ^-^",
                        "just a moment ^o^",
                        "follow us on <a href='https://www.facebook.com/thinkfieldapp/'>facebook</a> :D",
                        "eat your vegetables ;)",
                        "get ready ^_^",
                    ];

                    console.log("subbed 68: " + this._token);

                    console.info($);
                    $('body').addClass('gray-bg');
                    $("#modal .imageHolder .tip p").html(loadingTexts[Math.floor(Math.random() * loadingTexts.length)]);


                    if (this.getToken().length <= 0) {
                        console.info("sets token to def")
                        this.token = 'karkanIsTheGreatest';
                    }


                    //FieldImporter.getField("facebook_1568387783230267%2Fthinkfield", "1272AD56-3F9F-42B7-A6BE-83EA1D0C260A", init);
                    //FieldImporter.getField("facebook_10208652285597199%2Fthinkfield", "AAB4C76C-F565-4888-B65E-71ED55DAAA5B", init);
//token = "karkanIsTheGreatest"
                    if (this._token == "") {
                        window.location.href = "https://thinkfield.li";
                    }
                    else
                        FieldImporter.getField(this._token, this.initCanvas);
                    // In a real app: dispatch action to load the details here.
                }
            }
            );
};


private
initCanvas = (field) => {
    //console.log("lets begin");

    let canvas = new Canvas(field);
    //// Setting up all the Hammer events
    canvas.mc.on("panstart", function (ev) {
        canvas.setAnchor();
        //console.log("panstart anchor: " + canvas.xOff + "|" + canvas.yOff);
        //console.log(ev)
        //console.log(anchor)
        if (!canvas.didPan) {
            // nuttin
        }
    });
    canvas.mc.on("panmove", function (ev) {
        let anyEvent = ev as any;
        canvas.ctx.moveTo(ev.deltaX, anyEvent.deltaY);
        //console.log("Move: " + ev.deltaX + " | " + ev.deltaY);
        //console.log(ev);

        canvas.panning(ev.deltaX, anyEvent.deltaY);

        //console.log("new pos: " + canvas.xOff + "|" + canvas.yOff);

        canvas.draw();
    });
    canvas.mc.on("panend", function (ev) {


        //console.log("pan end");

        canvas.draw();
    });

    canvas.mc.on("pinchstart", function (ev) {

        ev.preventDefault();
        //console.log("pinchstart");
        //console.log(ev);
        canvas.zoomAnchor = canvas.zoom;

        canvas.setAnchor();

    });
    canvas.mc.on("pinchmove", function (ev) {

        ev.preventDefault();
        //console.log("pinch");
        //console.log(ev);

        //canvas.ctx.moveTo(ev.deltaX, ev.deltaY);
        //console.log("Move: " + ev.deltaX + " | " + ev.deltaY);
        //console.log(ev);

        canvas.panning(ev.deltaX, ev.deltaY);
        canvas.pinching(canvas.zoomAnchor * ev.scale, ev.center.x, ev.center.y);
    });


    // Setting up Mouse Wheel Event
    $("body").on("wheel", function (e) {
        e.preventDefault();
        let originalEvent = e.originalEvent as any
        canvas.zooming(originalEvent.deltaY, originalEvent.clientX, originalEvent.clientY);
    });

    // setting up resizing listener
    $(window).resize(function () {
        canvas.resize();
    });

    // allow pinch to zoom
    canvas.mc.get('pinch').set({enable: true});

    //canvas.setZoom(1, 1, 1);
    //for demo
    //canvas.panning(canvas.width * .5, canvas.height * .5);
    canvas.setZoom(1, 0, 0);
    canvas.xOff = $(window).width() * .5;
    canvas.yOff = $(window).height() * .5;
    canvas.draw();

    $("body").append("" +
        "<style type='text/css'>" +
        "#modal .imageHolder .loadSiteAnim p::before {" +
        "animation-iteration-count: 1!important;" +
        "animation-direction: normal!important;}" +
        "</style>");
    setTimeout(function () {
        console.info("canvasdrawer");
        canvas.draw();
        $("#modal").removeClass("show");
    }, 1000);
}
}