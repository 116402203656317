import {FieldImporter} from './FieldImporter';
import {NodeContentView} from "./nodeview";
import {CanvasItem} from "./canvasitem";
import {ConnectionView} from "./connection";
import {Field} from "./model/Field";
import {NodeContainerView} from "./nodeconatinerview";
import {TextNodeContentView} from "./textnodecontentview";
import {ImageNodeContentView} from "./imagenodecontentview";
import {WebsiteNodeContentView} from "./websitenodecontentview";
import {FieldNodeContentView} from "./fieldnodecontentview";
import * as Hammer from 'hammerjs';
import * as $ from 'jquery';



export class Canvas {

  // Canvas grundlegende Daten
  public c: HTMLCanvasElement;
  // Funktioniert, da HTMLCanvasElement. Der TypeScript Compiler checkt es nur nicht
  public ctx: CanvasRenderingContext2D;

  //type-erased HammerInstance
  public mc: any;

  public prevZoom: number = 1;
  public xOff: number = 0;
  public yOff: number = 0;

  public z: number = 1;

  public width: number = 1200;
  public height: number = 900;

  public anchor: any = {"xPos": 0, "yPos": 0};

  public nodeContents: Map<string, NodeContentView> = new Map();
  public nodes: Map<string, CanvasItem> = new Map();
  public connections: Map<string, ConnectionView> = new Map();

  public field: any;
  public avgDive: number = 0;
  public minDive: number = 0;

  public zoom: number = .15;
  public zoomAnchor: number = 1;
  public zoomPerScroll: number = 100.0;
  public zoomSpeedModify: number = .1;
  public maxZoom: number = 2.0;
  public minZoom: number = 0.01;

  public didPan = false;


  constructor(field: Field) {


    this.c = <HTMLCanvasElement> document.getElementById('canvas');
    this.ctx = this.c.getContext('2d');
    this.mc = new Hammer(this.c);

    this.field = field;
    this.parseField();


    // adjust dimensions
      let zoom = this.zoom;
    this.resize();
    this.connections.forEach(function (value) {
      value.calc(zoom);
    });


  }

  public parseField(): void
  {
    let items = this.field.items;
    for (let item of items)
    {

      //console.log(item);
      let ci = new CanvasItem(item.uuid, item.xPos, item.yPos, item.width, item.scale, item.diveScore);
      let containers = item.containers;
      let i = 0;
      for (let container of containers)
      {
        let n = container.nodes;
        let nodeContainer = new NodeContainerView(container.uuid, ci, i, container.style, container.dataType);
        //console.log(container);
        //console.log(nodeContainer);
        //console.log("new node container style: " + container._style);
        i++;
        for (let node of n)
        {
          let nodeContent;
          if (node.type == 1)
          {
            nodeContent = new TextNodeContentView(node.uuid, nodeContainer, node.stringValue, node.stringValue);
          }
          else if (node.type == 2)
          {
            //console.log(node);
            nodeContent = new ImageNodeContentView(node.uuid, nodeContainer, node.resource.content, node.stringValue);
          }
          else if (node.type == 3)
          {
            //console.log(node);
            nodeContent = new WebsiteNodeContentView(node.uuid, nodeContainer, node.resource.content, node.stringValue);
          }
          else if (node.type == 4)
          {
            //console.log(node);
            nodeContent = new FieldNodeContentView(node.uuid, nodeContainer, node.resource.content, node.stringValue);
          }
          else
          {
            nodeContent = new NodeContentView(node.uuid, nodeContainer, node.stringValue, node.stringValue, node.type, -1);
          }
          this.nodeContents.set(node.uuid, nodeContent);
          nodeContainer.nodes.push(nodeContent);
        }
        ci.containers.push(nodeContainer);

      }

      this.nodes.set(ci.uuid, ci);
      ci.update(this.ctx, this.zoom, this.avgDive, this.minDive);
      this.avgDive += ci.diveScore;
      if (this.minDive == 0)
        this.minDive = ci.diveScore;
      this.minDive = Math.min(this.minDive, ci.diveScore);
    }
    this.avgDive = this.avgDive / this.nodes.size;

    //console.log("avg: " + this.avgDive);
    // draw first image
    //this.draw();

    //console.log(this.nodeContents);

    for (let item of items)
    {
      let containers = item.containers;
      for (let container of containers)
      {
        let conns = container.connections;
        for (let conn of conns)
        {
          try
          {
            //console.log("conn: " + conn.uuid + " from a: " + conn.aNode.uuid + " and b: " + conn.bNode.uuid);
            let connection = new ConnectionView(conn.uuid, this.nodeContents.get(conn.aNode.uuid).parent, this.nodeContents.get(conn.bNode.uuid).parent);
            this.connections.set(connection.uuid, connection);
            connection.calc(this.zoom);

          }
          catch (e)
          {
            console.log("error while creating connection, moving on");
          }
        }
      }
    }


    //let sortedNodes = new Array(this.nodes.entries()).sort(function (n1,n2)
    //{
    //  return n1..diveScore - n2;
    //});

    let sortedNodes = Array.from(this.nodes.values()).sort((aNode, bNode): number => {
      return aNode.diveScore - bNode.diveScore;
    });

    this.nodes = new Map();
    for (let node of sortedNodes)
    {
      this.nodes.set(node.uuid, node);
    }
    //console.log(sortedNodes);

    // draw first image
    this.draw();

  }



  public zooming(z, x, y): any
  {
    let change = (z / this.zoomPerScroll) * this.zoomSpeedModify;
    if (this.zoom > .75)
    {
      change = (z / this.zoomPerScroll) * this.zoomSpeedModify;
    }
    else if (this.zoom > .5)
    {
      change = (z / this.zoomPerScroll) * this.zoomSpeedModify * .5;
    }
    else if (this.zoom > .25)
    {
      change = (z / this.zoomPerScroll) * this.zoomSpeedModify * .25;
    }
    else if (this.zoom > .1)
    {
      change = (z / this.zoomPerScroll) * this.zoomSpeedModify * .1;
    }
    else
    {
      change = (z / this.zoomPerScroll) * this.zoomSpeedModify * .05;
    }

    this.setZoom(this.zoom - change, x, y);
  }

  public pinching(s, x, y): any
  {
    this.setZoom(s, x, y);
  }

  public setZoom(z, x, y): any
  {
    let prev = this.zoom;
    this.zoom = z;
    this.zoom = Math.min(this.maxZoom, Math.max(this.zoom, this.minZoom));

    //this.xOff += this.xOff * (prev - this.zoom);
    //this.yOff += this.yOff * (prev - this.zoom);

    let xPrevShown = this.width / prev;
    let xShown = this.width / this.zoom;
    let yPrevShown = this.height / prev;
    let yShown = this.height / this.zoom;
    let xChange = (xShown - xPrevShown) * (x / xShown) / this.zoom;
    let yChange = (yShown - yPrevShown) * (y / yShown) / this.zoom;
    this.xOff = this.xOff + xChange;
    this.yOff = this.yOff + yChange;

    for (let t of Array.from(this.nodes.values()))
    {
      t.clearHoles();
      //t.update(this.ctx, this.zoom, this.avgDive, this.minDive);
    }
    for (let c of Array.from(this.connections.values()))
    {
      //c.calc(this.zoom);
    }
    //console.log(e.originalEvent.deltaY);
    console.log(this.zoom);
    this.draw();
  }

  public setAnchor()
  {
    this.anchor.xPos = this.xOff * this.zoom;
    this.anchor.yPos = this.yOff * this.zoom;
  }

  public panning(x, y)
  {
    this.xOff = (x + this.anchor.xPos) / this.zoom;
    this.yOff = (y + this.anchor.yPos) / this.zoom;
  }

  public draw(): any
  {


    let twice = false;
    this.prevZoom = this.zoom;

    this.ctx.clearRect(0, 0, this.width, this.height);
    this.ctx.moveTo(0, 0);
    this.ctx.beginPath();
    this.ctx.rect(0, 0, this.width, this.height);
    this.ctx.fillStyle = "#fafafa";
    this.ctx.fill();
    this.ctx.closePath();
    for (let t of Array.from(this.nodes.values()))
    {
      let scale = t.scale;
      t.update(this.ctx, this.zoom, this.avgDive, this.minDive);
      //console.log("check " +  scale);
      //twice = twice || (t.scale < 1 && scale >= 1);
    }
    for (let c of Array.from(this.connections.values()))
    {
      c.calc(this.zoom);
      c.draw(this.ctx, this.xOff * this.zoom, this.yOff * this.zoom, this.zoom);
    }
    for (let t of Array.from(this.nodes.values()))
    {
      t.draw(this.ctx, this.xOff * this.zoom, this.yOff * this.zoom, this.zoom, this.avgDive, this.minDive);
    }

    if (twice)
    {
      console.log("redraw");
      //this.draw();
    }

  }

  public resize(): any
  {

    let canvas = $("#canvas")[0] as HTMLCanvasElement;
    canvas.width = $(window).width() - 4; //document.width is obsolete
    canvas.height = $(window).height() - 4; //document.height is obsolete
    this.width = canvas.width;
    this.height = canvas.height;

    this.draw();


  }


}
