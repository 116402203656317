

import {NodeContentView} from "./nodeview";
import {Drawable} from "./drawable";
import {NodeContainerView} from "./nodeconatinerview";

export class ImageNodeContentView extends NodeContentView implements Drawable{

  protected icon = "";

  protected src: string;
  protected srcMedium: string;

  constructor(uuid: string, parent: NodeContainerView, content: any, stringValue: string) {
    super(uuid, parent, content, stringValue, 2, -1);


    this.srcMedium = "data:image/jpg;base64," + this.content.preview.thumbnailMedium;
    this.src = "data:image/jpg;base64," + this.content.preview.thumbnailSmall;
    this.ratio = this.content.preview.ratio;
    this.ratio = 1.3248731;
    console.log("ratio " + this.ratio);

    //console.log("new image: " + this.src);


    this.img = new Image();
    //this.img.src = 'https://black-points.net/gallery/thumbnails/6.png';
    //this.img.src = 'https://black-points.net/gallery/full/12.jpeg';
    this.img.src = this.src;
    this.calcAvgColor();



  }



  draw(ctx: any, xOff: number, yOff: number, zoom: number)
  {


    this.drawImage(ctx, xOff, yOff, zoom);
    if (zoom > 1)
    {
      this.img.src = this.srcMedium;
      this.drawImage(ctx, xOff, yOff, zoom);
    }

  }



  public isImage(): boolean {
    return true;
  }


  public calcAvgColor(): any {

    let n = this;

    this.img.onload = function (e) {
      let res = n.getAverageRGB(n.img);

      n.avgCol.r = res.r;
      n.avgCol.g = res.g;
      n.avgCol.b = res.b;

      n.hasColor = true;
    };
  }
}
