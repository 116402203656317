
export class Resource {

  binValue: Array<any> = undefined;

  filePath: string = undefined;

  public content: any;


  constructor(content: any) {
    this.content = content;
  }
}
