



import {NodeContentView} from "./nodeview";
import {Drawable} from "./drawable";
import {NodeContainerView} from "./nodeconatinerview";

export class FieldNodeContentView extends NodeContentView implements Drawable{

  protected icon = "";

  protected src: string;
  protected srcMedium: string;

  protected teamId: string;
  protected fieldUuid: string;

  constructor(uuid: string, parent: NodeContainerView, content: any, stringValue: string) {
    super(uuid, parent, content, stringValue, 4, -1);


    //this.srcMedium = "data:image/jpg;base64," + this.content.preview.thumbnailMedium;
    //this.src = "data:image/jpg;base64," + this.content.preview.thumbnailSmall;
    //this.ratio = this.content.preview.ratio;
    //this.ratio = 1;



    let values = JSON.parse(this.stringValue);

    this.fieldUuid = values.uuid;
    this.teamId = values.teamID;

    this.stringValue = " field node - available in our iOS app";

    this.lines[0] = " field node";
    this.lines[1] = "available in our iOS app";
    //console.log("vals");
    //console.log(values);
    //console.log("new image: " + this.src);


    //this.img = new Image();
    //this.img.src = 'https://black-points.net/gallery/thumbnails/6.png';
    //this.img.src = 'https://black-points.net/gallery/full/12.jpeg';
    //this.img.src = this.src;



  }


  draw(ctx: any, xOff: number, yOff: number, zoom: number)
  {
    //console.log("yOff: " + yOff);
    //console.log(this.lines[0] + " width: " + this.maxTextWidth(ctx, zoom));
    switch (this.parent.style)
    {
      case 0:
      case 1:
        //this.customHeight = 48;
        let z = zoom / this.parent.getScale();
        if (z < .5)
        {
          this.drawIcon(ctx, xOff, yOff, zoom);
          return;
        }
        this.drawTitle(ctx, xOff, yOff, zoom);

    }

  }

}
